.c1qxyygn{background:var(--gsu-brown-10);text-align:left;max-height:90vh;overflow-y:auto;}
.c17ghw43{padding:0 1rem;}
.r1p5gtxg{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-box-pack:justify;-webkit-justify-content:space-between;-ms-flex-pack:justify;justify-content:space-between;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;}
.ban9a8y{border:1px solid #efe7db;}
.cwamm1z{background:var(--gsu-grey-60);padding:1rem;border-radius:1rem;font-size:12px;margin-top:8px;}
.h1okhhku{font-size:24px;line-height:32px;-webkit-letter-spacing:-0.02em;-moz-letter-spacing:-0.02em;-ms-letter-spacing:-0.02em;letter-spacing:-0.02em;color:#19171d;}
.sk7ewgo{padding:1rem;background-color:var(--gsu-grey-20);border-radius:0.5rem;line-height:1.35;margin-bottom:1rem;font-size:0.85rem;}
.s119vvq9{opacity:0.5;-webkit-filter:blur(2px);filter:blur(2px);pointer-events:none;-webkit-user-select:none;-moz-user-select:none;-ms-user-select:none;user-select:none;}
.d1krdxef{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;-webkit-box-pack:justify;-webkit-justify-content:space-between;-ms-flex-pack:justify;justify-content:space-between;background:var(--gsu-brown-20);padding:1rem;border-radius:0.5rem;font-weight:700;}
.c1udhbof{accent-color:var(--gsu-blue-80);height:1.25rem;width:1.25rem;cursor:pointer;}
.bhdm3os{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;margin-top:0.25rem;}.bhdm3os button{-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;border-radius:1.5rem;border:0;color:var(--gsu-white);display:-webkit-inline-box;display:-webkit-inline-flex;display:-ms-inline-flexbox;display:inline-flex;font-size:1rem;margin-right:1rem;padding:0.75rem 1rem;cursor:pointer;}
.b1kbfa2t{background:var(--gsu-blue);}.b1kbfa2t span{margin-left:0.25rem;}
.olx2jog{background:var(--gsu-orange-80);}.olx2jog span{margin-left:0.25rem;}
