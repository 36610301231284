@font-face {
  font-family: 'icomoon';
  src: url('/fonts/icomoon.eot?pgmpa2');
  src: url('/fonts/icomoon.eot?pgmpa2#iefix') format('embedded-opentype'),
    url('/fonts/icomoon.ttf?pgmpa2') format('truetype'), url('/fonts/icomoon.woff?pgmpa2') format('woff'),
    url('/fonts/icomoon.svg?pgmpa2#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^='icon-'],
[class*=' icon-'] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-activity:before {
  content: '\e900';
}
.icon-alarm:before {
  content: '\e901';
}
.icon-alert:before {
  content: '\e902';
}
.icon-apps:before {
  content: '\e903';
}
.icon-arcade:before {
  content: '\e904';
}
.icon-archive:before {
  content: '\e905';
}
.icon-arrow-down:before {
  content: '\e906';
}
.icon-arrow-left:before {
  content: '\e907';
}
.icon-arrow-right:before {
  content: '\e908';
}
.icon-arrow-up:before {
  content: '\e909';
}
.icon-audio-status-active:before {
  content: '\e90a';
}
.icon-audio-status:before {
  content: '\e90b';
}
.icon-award:before {
  content: '\e90c';
}
.icon-bell:before {
  content: '\e90d';
}
.icon-book:before {
  content: '\e90e';
}
.icon-box:before {
  content: '\e90f';
}
.icon-briefcase:before {
  content: '\e910';
}
.icon-bulb:before {
  content: '\e911';
}
.icon-calculator:before {
  content: '\e912';
}
.icon-calendar-full:before {
  content: '\e913';
}
.icon-calendar:before {
  content: '\e914';
}
.icon-camera-plus:before {
  content: '\e915';
}
.icon-capture:before {
  content: '\e916';
}
.icon-chat:before {
  content: '\e917';
}
.icon-check:before {
  content: '\e918';
}
.icon-checkbox:before {
  content: '\e919';
}
.icon-checket:before {
  content: '\e91a';
}
.icon-checks:before {
  content: '\e91b';
}
.icon-chevron-down:before {
  content: '\e91c';
}
.icon-chevron-left:before {
  content: '\e91d';
}
.icon-chevron-right:before {
  content: '\e91e';
}
.icon-chevron-up:before {
  content: '\e91f';
}
.icon-class:before {
  content: '\e920';
}
.icon-click:before {
  content: '\e921';
}
.icon-clipboard-check:before {
  content: '\e922';
}
.icon-clipboard:before {
  content: '\e923';
}
.icon-clock:before {
  content: '\e924';
}
.icon-close:before {
  content: '\e925';
}
.icon-compass:before {
  content: '\e926';
}
.icon-copy:before {
  content: '\e927';
}
.icon-crown-full:before {
  content: '\e928';
}
.icon-desktop:before {
  content: '\e929';
}
.icon-devices:before {
  content: '\e92a';
}
.icon-dialog:before {
  content: '\e92b';
}
.icon-dice:before {
  content: '\e92c';
}
.icon-directions:before {
  content: '\e92d';
}
.icon-download:before {
  content: '\e92e';
}
.icon-duration:before {
  content: '\e92f';
}
.icon-ear:before {
  content: '\e930';
}
.icon-edit:before {
  content: '\e931';
}
.icon-enter:before {
  content: '\e932';
}
.icon-exit:before {
  content: '\e933';
}
.icon-explore-full:before {
  content: '\e934';
}
.icon-explore:before {
  content: '\e935';
}
.icon-exposure:before {
  content: '\e936';
}
.icon-eye-off:before {
  content: '\e937';
}
.icon-eye:before {
  content: '\e938';
}
.icon-face-id:before {
  content: '\e939';
}
.icon-facebook-empty:before {
  content: '\e93a';
}
.icon-facebook-full:before {
  content: '\e93b';
}
.icon-file-text:before {
  content: '\e93c';
}
.icon-file:before {
  content: '\e93d';
}
.icon-filters:before {
  content: '\e93e';
}
.icon-fingerprint:before {
  content: '\e93f';
}
.icon-flag:before {
  content: '\e940';
}
.icon-flame:before {
  content: '\e941';
}
.icon-flip-camera:before {
  content: '\e942';
}
.icon-focus:before {
  content: '\e943';
}
.icon-folder:before {
  content: '\e944';
}
.icon-folders:before {
  content: '\e945';
}
.icon-friends:before {
  content: '\e946';
}
.icon-gamepad:before {
  content: '\e947';
}
.icon-ghost:before {
  content: '\e948';
}
.icon-gift:before {
  content: '\e949';
}
.icon-glass-full:before {
  content: '\e94a';
}
.icon-globe:before {
  content: '\e94b';
}
.icon-grid-dots:before {
  content: '\e94c';
}
.icon-heart:before {
  content: '\e94d';
}
.icon-help:before {
  content: '\e94e';
}
.icon-history:before {
  content: '\e94f';
}
.icon-home-full:before {
  content: '\e950';
}
.icon-home:before {
  content: '\e951';
}
.icon-info:before {
  content: '\e952';
}
.icon-instagram:before {
  content: '\e953';
}
.icon-introduction-view:before {
  content: '\e954';
}
.icon-key:before {
  content: '\e955';
}
.icon-language:before {
  content: '\e956';
}
.icon-layout-horizontal:before {
  content: '\e957';
}
.icon-layout-list:before {
  content: '\e958';
}
.icon-layout-vertical:before {
  content: '\e959';
}
.icon-leaf:before {
  content: '\e95a';
}
.icon-link:before {
  content: '\e95b';
}
.icon-linkedin-full:before {
  content: '\e95c';
}
.icon-linkedIn:before {
  content: '\e95d';
}
.icon-list-checks:before {
  content: '\e95e';
}
.icon-list:before {
  content: '\e95f';
}
.icon-live:before {
  content: '\e960';
}
.icon-loader-circle:before {
  content: '\e961';
}
.icon-loader:before {
  content: '\e962';
}
.icon-location:before {
  content: '\e963';
}
.icon-lock-open:before {
  content: '\e964';
}
.icon-lock:before {
  content: '\e965';
}
.icon-login:before {
  content: '\e966';
}
.icon-logout:before {
  content: '\e967';
}
.icon-mail:before {
  content: '\e968';
}
.icon-map-pin:before {
  content: '\e969';
}
.icon-maximize:before {
  content: '\e96a';
}
.icon-menu:before {
  content: '\e96b';
}
.icon-mic-off:before {
  content: '\e96c';
}
.icon-mic:before {
  content: '\e96d';
}
.icon-minimize:before {
  content: '\e96e';
}
.icon-mobile:before {
  content: '\e96f';
}
.icon-more-actions:before {
  content: '\e970';
}
.icon-move-btm:before {
  content: '\e971';
}
.icon-move-top:before {
  content: '\e972';
}
.icon-newbie:before {
  content: '\e973';
}
.icon-news-full:before {
  content: '\e974';
}
.icon-news:before {
  content: '\e975';
}
.icon-note:before {
  content: '\e976';
}
.icon-notebook:before {
  content: '\e977';
}
.icon-notes:before {
  content: '\e978';
}
.icon-paperclip:before {
  content: '\e979';
}
.icon-pause:before {
  content: '\e97a';
}
.icon-pen:before {
  content: '\e97b';
}
.icon-people-add:before {
  content: '\e97c';
}
.icon-phone-call:before {
  content: '\e97d';
}
.icon-photo:before {
  content: '\e97e';
}
.icon-pinned:before {
  content: '\e97f';
}
.icon-plans:before {
  content: '\e980';
}
.icon-play-full:before {
  content: '\e981';
}
.icon-play:before {
  content: '\e982';
}
.icon-plus:before {
  content: '\e983';
}
.icon-pop-Out:before {
  content: '\e984';
}
.icon-presentation:before {
  content: '\e985';
}
.icon-puzzle:before {
  content: '\e986';
}
.icon-question:before {
  content: '\e987';
}
.icon-radio-off:before {
  content: '\e988';
}
.icon-react:before {
  content: '\e989';
}
.icon-reset:before {
  content: '\e98a';
}
.icon-resize-maximise:before {
  content: '\e98b';
}
.icon-resize-minimise:before {
  content: '\e98c';
}
.icon-retry:before {
  content: '\e98d';
}
.icon-room:before {
  content: '\e98e';
}
.icon-rotate:before {
  content: '\e98f';
}
.icon-sad:before {
  content: '\e990';
}
.icon-school:before {
  content: '\e991';
}
.icon-screen-share-off:before {
  content: '\e992';
}
.icon-search:before {
  content: '\e993';
}
.icon-selector:before {
  content: '\e994';
}
.icon-send:before {
  content: '\e995';
}
.icon-settings:before {
  content: '\e996';
}
.icon-shapes:before {
  content: '\e997';
}
.icon-share-screen:before {
  content: '\e998';
}
.icon-share:before {
  content: '\e999';
}
.icon-shield-check:before {
  content: '\e99a';
}
.icon-smile:before {
  content: '\e99b';
}
.icon-speakerphone:before {
  content: '\e99c';
}
.icon-star-empty:before {
  content: '\e99d';
}
.icon-star-full:before {
  content: '\e99e';
}
.icon-stop:before {
  content: '\e99f';
}
.icon-switch--camera:before {
  content: '\e9a0';
}
.icon-tablet:before {
  content: '\e9a1';
}
.icon-tag:before {
  content: '\e9a2';
}
.icon-trash:before {
  content: '\e9a3';
}
.icon-trophy:before {
  content: '\e9a4';
}
.icon-twitter-full:before {
  content: '\e9a5';
}
.icon-twitter:before {
  content: '\e9a6';
}
.icon-upload:before {
  content: '\e9a7';
}
.icon-user-check:before {
  content: '\e9a8';
}
.icon-user-full:before {
  content: '\e9a9';
}
.icon-user:before {
  content: '\e9aa';
}
.icon-users:before {
  content: '\e9ab';
}
.icon-video-off:before {
  content: '\e9ac';
}
.icon-video:before {
  content: '\e9ad';
}
.icon-view-columns:before {
  content: '\e9ae';
}
.icon-view-gallery:before {
  content: '\e9af';
}
.icon-view-one-to-one-off:before {
  content: '\e9b0';
}
.icon-view-one-to-one:before {
  content: '\e9b1';
}
.icon-view-speaker:before {
  content: '\e9b2';
}
.icon-view-stage:before {
  content: '\e9b3';
}
.icon-volume-off:before {
  content: '\e9b4';
}
.icon-volume:before {
  content: '\e9b5';
}
.icon-window:before {
  content: '\e9b6';
}
.icon-youtube:before {
  content: '\e9b7';
}
