html,
body {
  padding: 0;
  margin: 0;
  color: var(--gsu-black-80);
  background-color: var(--gsu-white);
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans,
    Helvetica Neue, sans-serif;
  overflow: hidden;

  /* app wide variables */
  --gsu-white: #ffffff;
  --gsu-dark: #19171d;
  --gsu-dark-grey: #4a4a68;
  --gsu-dark-grey-hover: #3a3a52;
  --gsu-grey-hover: #737386;

  --gsu-accent: #f5f5f5;
  --gsu-accent-hover: #eeeeee;
  --gsu-divider-dark: #33334b;
  --gsu-divider: #dadce0;
  --gsu-canvas: #0a090c;

  --gsu-primary-brand: #181b56;
  --gsu-primary-brand-hover: #10123a;
  --gsu-primary-brand-70: #b2c8ff;
  --gsu-primary-brand-20: #e0e9ff;

  --gsu-secondary-brand: #ff4600;
  --gsu-secondary-brand-hover: #dd3d00;
  --gsu-secondary-brand-70: #ff7845;
  --gsu-secondary-brand-20: #feddd1;
  --gsu-secondary-moderated: #ef423e;

  --gsu-tertiary-brand: #e2cda8;
  --gsu-tertiary-brand-hover: #e2cda8;
  --gsu-tertiary-brand-70: #f5ebd9;
  --gsu-tertiary-brand-20: #f9f5ef;

  --gsu-success-active: #14f0b4;
  --gsu-error-disabled: #ff5050;
  --gsu-white-active: #b2c8ffb3;

  --gsu-bottom-nav-height: 90px;
  --bp-mobile-max: 720px;

  /* Grey */
  --gsu-grey: #757582;
  --gsu-grey-60: #b1b1bf;
  --gsu-grey-20: #eaeaef;
  --gsu-grey-10: #f7f7fa;

  /* Orange */
  --gsu-orange: #db3c00;
  --gsu-orange-80: #ff4600;
  --gsu-orange-60: #ff7845;
  --gsu-orange-20: #feddd1;
  --gsu-orange-10: #fcece7;

  /* Blue */
  --gsu-blue: #181b56;
  --gsu-blue-80: #2c318a;
  --gsu-blue-60: #3c44d5;
  --gsu-blue-40: #88aaff;
  --gsu-blue-30: #b2c8ff;
  --gsu-blue-20: #e0e9ff;
  --gsu-blue-10: #e0e9ff;
  --gsu-blue-5: #f8faff;
  --gsu-cat-travel: #5567cf;

  /* Brown */
  --gsu-brown: #6e5529;
  --gsu-brown-40: #e0d5c3;
  --gsu-brown-20: #efe7db;
  --gsu-brown-10: #f9f5ef;

  /* Brown */
  --gsu-brown: #6e5529;
  --gsu-brown-40: #e0d5c3;
  --gsu-brown-20: #efe7db;
  --gsu-brown-10: #f9f5ef;

  /* Purple */
  --gsu-purple: #3b3b56;
  --gsu-purple-20: #4a4a68;

  /* Black */
  --gsu-black: #1c1c1f;
  --gsu-black-80: #2d2d32;
  --gsu-black-60: #464650;

  /* Green */
  --gsu-green: #23a868;
  --gsu-green-10: #eaf9f2;

  /* Red */
  --gsu-red: #e61535;
  --gsu-red-10: #fff2f1;

  /* Imported from GSU Web */
  --element-spacing: 24px;
  --element-spacing-xxxs: 4px;
  --element-spacing-xxs: 8px;
  --element-spacing-xs: 12px;
  --element-spacing-sm: 16px;
  --element-spacing-md: 24px;
  --element-spacing-lg: 32px;
  --element-spacing-xl: 48px;
  --element-spacing-xxl: 64px;
  --element-spacing-xxxl: 96px;

  --element-padding: 24px;
  --element-padding-xxxs: 4px;
  --element-padding-xxs: 8px;
  --element-padding-xs: 12px;
  --element-padding-sm: 16px;
  --element-padding-md: 24px;
  --element-padding-lg: 32px;
  --element-padding-xl: 48px;
  --element-padding-xxl: 64px;
  --element-padding-xxxl: 96px;
  --element-padding-xxxxl: 108px;

  --border-radius: 12px;
  --border-radius-xmd: 16px;
  --border-radius-xl: 32px;
  --border-radius-lg: 24px;
  --border-radius-md: 8px;
  --border-radius-sm: 6px;
  --border-radius-rem: 1rem;

  --font-size-xxxs: 12px;
  --font-size-xxs: 14px;
  --font-size-xs: 16px;
  --font-size-sm: 18px;
  --font-size-md: 20px;
  --font-size-lg: 24px;
  --font-size-xl: 32px;
  --font-size-xxl: 48px;

  --line-height-xxxs: 16px;
  --line-height-xxs: 20px;
  --line-height-xs: 22px;
  --line-height-sm: 24px;
  --line-height-md: 26px;
  --line-height-lg: 32px;
  --line-height-xl: 40px;
  --line-height-xxl: 56px;

  --font-weight-sm: 400;
  --font-weight-md: 500;
  --font-weight-semi-bold: 600;
  --font-weight-lg: 700;
  --font-weight-desktop-lg: 600;

  --bottom-nav-height: 60px;
  --desktop-nav-width: 100px;
  --desktop-header-height: 102px;
  --desktop-nav-menu-more-width: 450px;

  --rem-xxxs: 0.125rem;
  --rem-xxs: 0.25rem;
  --rem-xs: 0.375rem;
  --rem-sm: 0.5rem;
  --rem-md: 0.625rem;
  --rem-lg: 0.75rem;
  --rem-xl: 0.875rem;
  --rem-xxl: 1rem;

  --width-xxs: 18px;

  /* Transitions */

  --transition-fast: 0.2s;
  --transition: 0.4s;
  --transition-slow: 0.6s;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

/* Padding utility */
.p-xxxs {
  padding: var(--element-padding-xxxs) !important;
}
.p-xxs {
  padding: var(--element-padding-xxs) !important;
}
.p-xs {
  padding: var(--element-padding-xs) !important;
}
.p {
  padding: var(--element-padding) !important;
}
.p-sm {
  padding: var(--element-padding-sm) !important;
}
.p-md {
  padding: var(--element-padding-md) !important;
}
.p-lg {
  padding: var(--element-padding-lg) !important;
}
.pt {
  padding-top: var(--element-padding) !important;
}
.pt-xs {
  padding-top: var(--element-padding-xs) !important;
}
.pt-sm {
  padding-top: var(--element-padding-sm) !important;
}
.pt-md {
  padding-top: var(--element-padding-md) !important;
}
.pt-lg {
  padding-top: var(--element-padding-lg) !important;
}
.pt-xl {
  padding-top: var(--element-padding-xl) !important;
}
.pt-xxl {
  padding-top: var(--element-padding-xxl) !important;
}
.pt-xxxl {
  padding-top: var(--element-padding-xxxl) !important;
}
.pt-xxxxl {
  padding-top: var(--element-padding-xxxxl) !important;
}
.pr {
  padding-right: var(--element-padding) !important;
}
.pr {
  padding-right: var(--element-padding) !important;
}
.pr-xxs {
  padding-right: var(--element-padding-xxs) !important;
}
.pr-xs {
  padding-right: var(--element-padding-xs) !important;
}
.pr-sm {
  padding-right: var(--element-padding-sm) !important;
}
.pr-md {
  padding-right: var(--element-padding-md) !important;
}
.pr-lg {
  padding-right: var(--element-padding-lg) !important;
}
.pr-xl {
  padding-right: var(--element-padding-xl) !important;
}
.pr-xxl {
  padding-right: var(--element-padding-xxl) !important;
}
.pb-0 {
  padding-bottom: 0 !important;
}
.pb {
  padding-bottom: var(--element-padding) !important;
}
.pb-xs {
  padding-bottom: var(--element-padding-xs) !important;
}
.pb-sm {
  padding-bottom: var(--element-padding-sm) !important;
}
.pb-md {
  padding-bottom: var(--element-padding-md) !important;
}
.pb-lg {
  padding-bottom: var(--element-padding-lg) !important;
}
.pb-xl {
  padding-bottom: var(--element-padding-xl) !important;
}
.pb-xxl {
  padding-bottom: var(--element-padding-xxl) !important;
}
.pl {
  padding-left: var(--element-padding) !important;
}
.pl-0 {
  padding-left: 0 !important;
}
.pl-xxs {
  padding-left: var(--element-padding-xxs) !important;
}
.pl-xs {
  padding-left: var(--element-padding-xs) !important;
}
.pl-sm {
  padding-left: var(--element-padding-sm) !important;
}
.pl-md {
  padding-left: var(--element-padding-md) !important;
}
.pl-lg {
  padding-left: var(--element-padding-lg) !important;
}
.pl-xl {
  padding-left: var(--element-padding-xl) !important;
}
.pl-xxl {
  padding-left: var(--element-padding-xxl) !important;
}
.px {
  padding-left: var(--element-padding) !important;
  padding-right: var(--element-padding) !important;
}
.px-0 {
  padding-left: 0 !important;
  padding-right: 0 !important;
}
.px-xs {
  padding-left: var(--element-padding-xs) !important;
  padding-right: var(--element-padding-xs) !important;
}
.px-sm {
  padding-left: var(--element-padding-sm) !important;
  padding-right: var(--element-padding-sm) !important;
}
.px-md {
  padding-left: var(--element-padding-md) !important;
  padding-right: var(--element-padding-md) !important;
}
.px-lg {
  padding-left: var(--element-padding-lg) !important;
  padding-right: var(--element-padding-lg) !important;
}
.px-xl {
  padding-left: var(--element-padding-xl) !important;
  padding-right: var(--element-padding-xl) !important;
}
.px-xxl {
  padding-left: var(--element-padding-xxl) !important;
  padding-right: var(--element-padding-xxl) !important;
}
.py {
  padding-top: var(--element-padding) !important;
  padding-bottom: var(--element-padding) !important;
}
.py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}
.py-xxs {
  padding-top: var(--element-padding-xxs) !important;
  padding-bottom: var(--element-padding-xxs) !important;
}
.py-xs {
  padding-top: var(--element-padding-xs) !important;
  padding-bottom: var(--element-padding-xs) !important;
}
.py-sm {
  padding-top: var(--element-padding-sm) !important;
  padding-bottom: var(--element-padding-sm) !important;
}
.py-md {
  padding-top: var(--element-padding-md) !important;
  padding-bottom: var(--element-padding-md) !important;
}
.py-lg {
  padding-top: var(--element-padding-lg) !important;
  padding-bottom: var(--element-padding-lg) !important;
}
.py-xl {
  padding-top: var(--element-padding-xl) !important;
  padding-bottom: var(--element-padding-xl) !important;
}
.py-xxl {
  padding-top: var(--element-padding-xxl) !important;
  padding-bottom: var(--element-padding-xxl) !important;
}

.p-0 {
  padding: 0 !important;
}
.pt-0 {
  padding-top: 0 !important;
}

.pr-0 {
  padding-right: 0 !important;
}

.pb-0 {
  padding-bottom: 0 !important;
}

.pl-0 {
  padding-left: 0 !important;
}

/* Margin utility */
.mt {
  margin-top: var(--element-spacing) !important;
}
.mt-xxs {
  margin-top: var(--element-spacing-xxs) !important;
}
.mt-xs {
  margin-top: var(--element-spacing-xs) !important;
}
.mt-sm {
  margin-top: var(--element-spacing-sm) !important;
}
.mt-md {
  margin-top: var(--element-spacing-md) !important;
}
.mt-lg {
  margin-top: var(--element-spacing-lg) !important;
}
.mt-xl {
  margin-top: var(--element-spacing-xl) !important;
}
.mt-xxl {
  margin-top: var(--element-spacing-xxl) !important;
}
.mr {
  margin-right: var(--element-spacing) !important;
}
.mr-xxxs {
  margin-right: var(--element-spacing-xxxs) !important;
}
.mr-xxs {
  margin-right: var(--element-spacing-xxs) !important;
}
.mr-xs {
  margin-right: var(--element-spacing-xs) !important;
}
.mr-sm {
  margin-right: var(--element-spacing-sm) !important;
}
.mr-md {
  margin-right: var(--element-spacing-md) !important;
}
.mr-lg {
  margin-right: var(--element-spacing-lg) !important;
}
.mr-xl {
  margin-right: var(--element-spacing-xl) !important;
}
.mr-xxl {
  margin-right: var(--element-spacing-xxl) !important;
}
.mb {
  margin-bottom: var(--element-spacing) !important;
}
.mb-xxs {
  margin-bottom: var(--element-spacing-xxs) !important;
}
.mb-xs {
  margin-bottom: var(--element-spacing-xs) !important;
}
.mb-sm {
  margin-bottom: var(--element-spacing-sm) !important;
}
.mb-md {
  margin-bottom: var(--element-spacing-md) !important;
}
.mb-lg {
  margin-bottom: var(--element-spacing-lg) !important;
}
.mb-xl {
  margin-bottom: var(--element-spacing-xl) !important;
}
.mb-xxl {
  margin-bottom: var(--element-spacing-xxl) !important;
}
.ml {
  margin-left: var(--element-spacing) !important;
}
.ml-xxs {
  margin-left: var(--element-spacing-xxs) !important;
}
.ml-xs {
  margin-left: var(--element-spacing-xs) !important;
}
.ml-sm {
  margin-left: var(--element-spacing-sm) !important;
}
.ml-md {
  margin-left: var(--element-spacing-md) !important;
}
.ml-lg {
  margin-left: var(--element-spacing-lg) !important;
}

.m-0 {
  margin: 0 !important;
}
.mt-0 {
  margin-top: 0 !important;
}
.ml-0 {
  margin-left: 0 !important;
}
.mr-0 {
  margin-right: 0 !important;
}
.mb-0 {
  margin-bottom: 0 !important;
}

.m-auto {
  margin: auto;
}

.cursor-default {
  cursor: default !important;
}
